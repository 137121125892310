import AxiosIns from "@/libs/axios";
import axios from "axios";
import { BACKEND_BASE_URL } from '@/config';

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async(err) => {
        if (err.response.status == 401) {
            try {
                const response = await AxiosIns.post("/token_refresh", {
                    headers: { Authorization: localStorage.getItem("access_token") },
                });
                localStorage.setItem(
                    "access_token",
                    response.data[0].token_refresh.new_token
                );
            } catch {
                location = "/login";
            }
        }
        return Promise.reject(err);
    }
);

export class Requests {
    //Registrazione Utente

    static async register(data) {
        const response = await AxiosIns.post("/register", data);
        return response.data[0];
    }

    //Login

    static async login(data) {
        const response = await AxiosIns.post("/login", data);
        return response.data[0];
    }

    //Password Recovery

    static async passwordRecovery(data) {
        const response = await AxiosIns.post("/lost_password", data);
        return response.data[0];
    }

    //Password Recovery Verify

    static async passwordRecoveryVerify(data) {
        const response = await AxiosIns.post("/lost_password_verify", data);
        return response.data[0];
    }

    // cambio password

    static async changePassword(data) {
        const response = await AxiosIns.post("/change_password", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data[0];
    }
    //Dati Utente in Sessione

    static async getUser() {
        const response = await AxiosIns.get("/me", {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data[0];
    }

    //Profilo Utente

    static async getUserProfile() {
        const response = await AxiosIns.get("/profile", {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    //Modifica Profilo Utente

    static async editUserProfile(data) {
        const response = await AxiosIns.post("/profile_edit", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    static async updateCredits(data) {
        const response = await AxiosIns.post("/credit_update", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data[0];
    }

    //Creazione Nuovo Progetto

    static async createProject(data) {
        const response = await AxiosIns.post("/project_addnew", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data[0];
    }

    static async createMaintenanceProject(data) {
        const response = await AxiosIns.post("/project_maintenance_addnew", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data[0];
    }

    //Modifica Progetto

    static async editProject(data) {
        const response = await AxiosIns.post("/project_edit", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    static async editMaintenanceProject(data) {
        const response = await AxiosIns.post("/project_maintenance_edit", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    // elimina progetto 
    static async deleteProject(data) {
        const response = await AxiosIns.post("/project_delete", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    // elimina progetto manutenzione
    static async deleteMaintenanceProject(data) {
        const response = await AxiosIns.post("/project_maintenance_delete", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    //Dettagli Progetto

    static async getProject(data) {
        const response = await AxiosIns.post("/project_details", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    //Dettagli Progetto Manutenzione

    static async getMaintenanceProject(data) {
        const response = await AxiosIns.post("/project_maintenance_details", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    //Tabella Progetti Salvati

    static async getAllProjects() {
        const response = await AxiosIns.get("/project_datatable", {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response;
    }

    //Tabella Clienti

    static async getAllCustomers() {
        const response = await AxiosIns.get("/customer_datatable", {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response;
    }

    //Lista Gas Refrigeranti

    static async getRefrigerantGasList() {
        const response = await AxiosIns.get("/component_refrigerant");
        return response;
    }

    //Lista Gas Refrigeranti Suggest

    static async getRefrigerantGasSuggestList() {
        const response = await AxiosIns.get("/component_refrigerant_suggest");
        return response.data;
    }

    //Image Uploader

    static async uploadImage(data) {
        const response = await axios.post(
            BACKEND_BASE_URL + "/upload.php",
            data, { headers: { "content-type": "multipart/form-data" } }
        );
        return response;
    }

    /* API Backend */

    //Login Backend

    static async loginBackend(data) {
        const response = await AxiosIns.post("/admin_login", data);
        return response.data[0];
    }

    //Lista Account Registrati

    static async getAccountList(data) {
        const response = await AxiosIns.post("/account_list", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response;
    }

    //Dettagli Account Registrati

    static async getAccountDetails(data) {
        const response = await AxiosIns.post("/account_details", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data[0];
    }

    //Lista Progetti Account

    static async getAccountProjects(data) {
        const response = await AxiosIns.post("/account_project", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data;
    }

    //Dettagli Progetto Account

    static async getProjectBackend(data) {
        const response = await AxiosIns.post("/account_project_details", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data;
    }

    //Riepilogo Progetto

    static async getProjectSummary(data) {
        const response = await AxiosIns.post("/project_summary", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data;
    }



    static async confirmData(id) {
        const fd = new FormData();
        fd.append('id_project', id);
        const response = await AxiosIns.post(`/confirm_project`, fd, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    static async confirmMaintenanceData(id) {
        const fd = new FormData();
        fd.append('id_project', id);
        const response = await AxiosIns.post(`/confirm_maintenance_project`, fd, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    static async draftData(id) {
        const fd = new FormData();
        fd.append('id_project', id);
        const response = await AxiosIns.post(`/draft_project`, fd, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    static async draftMaintenanceData(id) {
        const fd = new FormData();
        fd.append('id_project', id);
        const response = await AxiosIns.post(`/draft_maintenance_project`, fd, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    static async adminConfirmData(id) {
        const fd = new FormData();
        fd.append('id_project', id);
        const response = await AxiosIns.post(`/confirm_project`, fd, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data;
    }

    static async adminConfirmMaintenanceData(id) {
        const fd = new FormData();
        fd.append('id_project', id);
        const response = await AxiosIns.post(`/confirm_maintenance_project`, fd, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data;
    }

    static async adminDraftData(id) {
        const fd = new FormData();
        fd.append('id_project', id);
        const response = await AxiosIns.post(`/draft_project`, fd, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data;
    }

    static async adminDraftMaintenanceData(id) {
        const fd = new FormData();
        fd.append('id_project', id);
        const response = await AxiosIns.post(`/draft_maintenance_project`, fd, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data;
    }


    static async getLicences() {
        const response = await AxiosIns.post('/subscription_plan', null, {
            headers: { Authorization: localStorage.getItem("access_token")}
        })
        return response;
    }
    static async getAdminLicences() {
        const response = await AxiosIns.post('/subscription_plan', null, {
            headers: { Authorization: localStorage.getItem("admin_access_token")}
        })
        return response;
    }
    static async saveAdminLicenses(data) {
        const response = await AxiosIns.post('/subscription_plan_edit', data, {
            headers: { Authorization: localStorage.getItem("admin_access_token")}
        });
        return response.data;
    }

    static async getProjectAttachments(data) {
        const response = await AxiosIns.post("/project_attachment", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    static async getProjectMaintenanceAttachments(data) {
        const response = await AxiosIns.post("/project_maintenance_attachment", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }

    static async checkout(data) {
        const response = await AxiosIns.post('/order_new', data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response.data;
    }


    static async getAgentList(data) {
        const response = await AxiosIns.post("/agent_list", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response;
    }

    static async getAgentDetails(data) {
        const response = await AxiosIns.post("/agent_details", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data[0];
    }

    static async updateAgent(data) {
        const response = await AxiosIns.post("/agent_update", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data[0];
    }
    static async createAgent(data) {
        const response = await AxiosIns.post("/agent_create", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response.data[0];
    }

    static async getPaymentList(data) {
        const response = await AxiosIns.post("/payment_list", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response;
    }

    static async getLostPaymentList(data) {
        const response = await AxiosIns.post("/lost_payment_list", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response;
    }

    static async getProxiedAccountList(data) {
        const response = await AxiosIns.post("/proxied_account_list", data, {
            headers: { Authorization: localStorage.getItem("admin_access_token") },
        });
        return response;
    }

    static async sendPricingRequest(data) {
        const response = await AxiosIns.post("/send_pricing_request", data, {
            headers: { Authorization: localStorage.getItem("access_token") },
        });
        return response;
    }

}